import logo from './logo.svg';
import './App.css';

import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import growth from './data/growth.json';
import pets from './data/pets.json';

import SortableTable from "./SortableTable";

import React, { useState } from "react";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


var datag = growth;
var datap = pets;

var level100 = datag[99];

const alldata = pets.map(pet => {
  var growth = pet.data.growth;
  var baseData = pet.data.stats;
  var levelBoost = level100.data[growth].totals;
  return {
    name: pet.data.name,
    health: baseData.hp + levelBoost.hp,
    speed: baseData.speed + levelBoost.speed,
    power: baseData.power + levelBoost.power,
    defence: baseData.defence + levelBoost.defence,
    element: pet.data.element,
  }
});


  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Health", accessor: "health" },
    { header: "Speed", accessor: "speed" },
    { header: "Power", accessor: "power" },
    { header: "Defence", accessor: "defence" },
    { header: "Element", accessor: "element" },
  ];


  const filter = {
    label: "Element",
    accessor: "element",
    values: [
      { name: "All", value: ""},
      { name: "Water", value: "water"},
      { name: "Fire", value: "fire"},
      { name: "Earth", value: "earth"},
      { name: "Shadow", value: "shadow"},
      { name: "Ice", value: "ice"},
      { name: "Storm", value: "storm"},
      { name: "Plant", value: "plant"},
    ]
  };



const FilterTable = ({ data }) => {
  const [filterElement, setFilterElement] = useState("");


  const filteredData = React.useMemo(() => {

    if (filterElement == "") {
      return data;
    }
    
    return data.filter(d => d.element == filterElement);

  }, [filterElement]);

  // const setFilterValue = (key) => {
  //   setFilterElement(key);
  // };


  return (
    <React.Fragment>
    <CssBaseline />
    <Container>
<Box>

      <label htmlFor="elementFilter">
        Filter by Element:
      </label>
      <select
      id="elementFilter"
       onChange={(e) => setFilterElement(e.target.value === 'All' ? '' : e.target.value)}
      >
        {filter.values.map((value) => (
          <option key={value.value} value={value.value}>
            {value.name}
          </option>
        ))}
      </select>

          <SortableTable data={filteredData} columns={columns}/>
          </Box>
    </Container>

          </React.Fragment>

  );
};


function App() {
  return (
    <div className="App">
      <FilterTable data={ alldata } />
    </div>
  );

}

export default App;
